exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-distillation-tsx": () => import("./../../../src/pages/distillation.tsx" /* webpackChunkName: "component---src-pages-distillation-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-presentazione-moreno-cv-tsx": () => import("./../../../src/pages/presentazione/morenoCV.tsx" /* webpackChunkName: "component---src-pages-presentazione-moreno-cv-tsx" */),
  "component---src-pages-presentazione-tsx": () => import("./../../../src/pages/presentazione.tsx" /* webpackChunkName: "component---src-pages-presentazione-tsx" */),
  "component---src-pages-projects-bucher-tsx": () => import("./../../../src/pages/projects/bucher.tsx" /* webpackChunkName: "component---src-pages-projects-bucher-tsx" */),
  "component---src-pages-projects-fluckiger-tsx": () => import("./../../../src/pages/projects/fluckiger.tsx" /* webpackChunkName: "component---src-pages-projects-fluckiger-tsx" */),
  "component---src-pages-projects-foehn-tsx": () => import("./../../../src/pages/projects/foehn.tsx" /* webpackChunkName: "component---src-pages-projects-foehn-tsx" */),
  "component---src-pages-projects-ghidoni-tsx": () => import("./../../../src/pages/projects/ghidoni.tsx" /* webpackChunkName: "component---src-pages-projects-ghidoni-tsx" */),
  "component---src-pages-projects-givel-tsx": () => import("./../../../src/pages/projects/givel.tsx" /* webpackChunkName: "component---src-pages-projects-givel-tsx" */),
  "component---src-pages-projects-huessen-tsx": () => import("./../../../src/pages/projects/huessen.tsx" /* webpackChunkName: "component---src-pages-projects-huessen-tsx" */),
  "component---src-pages-projects-osio-tsx": () => import("./../../../src/pages/projects/osio.tsx" /* webpackChunkName: "component---src-pages-projects-osio-tsx" */),
  "component---src-pages-projects-rossi-tsx": () => import("./../../../src/pages/projects/rossi.tsx" /* webpackChunkName: "component---src-pages-projects-rossi-tsx" */),
  "component---src-pages-projects-stutz-tsx": () => import("./../../../src/pages/projects/stutz.tsx" /* webpackChunkName: "component---src-pages-projects-stutz-tsx" */),
  "component---src-pages-projects-waldburger-tsx": () => import("./../../../src/pages/projects/waldburger.tsx" /* webpackChunkName: "component---src-pages-projects-waldburger-tsx" */)
}

